<template>
  <div class="page_msg main_top">
    <div class="list_title">About Us
        <div class="icon_back flex_center iconfont icon-back-line-free hidden-sm-and-up" @click="toBack()"></div>
    </div>
    <div class="page_content">
      <div class="content_banner">
        <img src="../../assets/image/logo.png" alt="">
      </div>
      <div class="list_tabs">
        <div class="tab_item flex_sb" v-for="(item,index) in tabs" :key="index" @click="toPage(item.path)">
          <div class="tab_name">{{ item.name }}</div>
          <div class="tab_icon iconfont icon-enter-line-free flex_center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      tabs:[
        {name:'Terms of Service',path:'/terms'},{name:'Privacy Policy',path:'privacyPolicy'},{name:'Cookie Policy',path:'cookiePolicy'}
      ]
    }
  },
    methods:{
        toBack(){
            this.$router.back()
        },
        toPage(path){
          this.$router.push(path)
        }
    }
}
</script>

<style lang="scss" scoped>
.page_msg{
  .list_title{
      text-align: center;
      height: 44px;
      margin:0 -16px 12px!important;
      line-height: 44px;
      position: relative;
      width: auto;
      background: #fff;
      font-weight: 600;
      .icon_back{
        font-size: 20px;
        position: absolute;
        left:16px;
        top:12px;
        height: 20px;
        cursor: pointer;
      }
    }
    .content_banner{
      background: url(https://static.playsavefristofficial.com/src/img/img_playsavefristofficial_bg.dabe53a4.png) no-repeat #fff;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 44px 0;
      margin-bottom: 4px;
      >img{
        width:180px;
      }
    }
    .page_content{
      margin:0 -16px;
    }
    .list_tabs{
      margin-top:6px;
      background-color: #fff;
      padding: 0 16px;
      .tab_item{
        height: 56px;
        font-size: 16px;
        color:#000;
        border-bottom: 1px solid #ebecf0;
        .tab_icon{
          font-size: 14px;
          width: 16px;
          height: 16px;
        }
      }
    }
}

</style>